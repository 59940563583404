import Card from './Card';
import './styles/Scope.scss';
import electric from './electric.svg';

const Scope = () => {
    return <Card>
        <Card.Header>
            <Card.Header.Title text="Software Engineering" img={electric} />
        </Card.Header>
        <Card.Body>
            <p>I specialize in Backend development, event-driven microservices, and IaC. Occasionally, I write some small C programs, shell suites, and containers to provide some utility. </p>

            <p>My favourite companions are: React, Node.js, C, PHP, POSIX Shell, GNU/Linux, AWS, and Bitcoin. </p>

            <p>Here are some of the projects I enjoyed working on:</p>

            <ul className="scope-projects">
                <li><a href="https://github.com/alichry/sge-utils" target="_blank">HPC job submission suite:</a> User-friendly executables to submit parallel programs.</li>
                <li><a href="https://github.com/alichry/alis-taa" target="_blank">Ali’s Teaching Assistant Assitant:</a> An assistant for when you are a teaching assistant.</li>
                <li><a href="https://github.com/alichry/aws-appsync-butler" target="_blank">AWS AppSync Butler:</a> Simple and intuitive framework for serverless GraphQL API development.</li>
            </ul>
        </Card.Body>
    </Card>
}

export default Scope;