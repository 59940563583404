import { Component, ImgHTMLAttributes } from 'react';
import findByType from './findByType';
import './styles/Card.scss';

class Title extends Component<{ img?: string, text: string }> {
    static displayName = "Title";

    render() {
        return <div className="card-title">
            { this.props.img ? <img src={this.props.img} /> : null }
            <h5>{this.props.text}</h5>
        </div>
    }
}

class Header extends Component {
    static displayName = "Header";
    static Title = Title;

    render() {
        return <div className="card-header">
            {this.props.children}    
        </div>
    }
}

class Body extends Component {
    static displayName = "Body";

    render() {
        return <div className="card-body">
            {this.props.children}    
        </div>
    }
}

class Footer extends Component {
    static displayName = "Footer";

    render() {
        return <div className="card-footer">
            {this.props.children}    
        </div>
    }
}

class Card extends Component {
    static Header = Header;
    static Body = Body;
    static Footer = Footer;
    static displayName = "Card";

    render() {
        const { children } = this.props;
        const header = findByType(children, Card.Header);
        const body = findByType(children, Card.Body);
        const footer = findByType(children, Card.Footer);

        console.log("Body:", body);
        return <div className="card">
            <div className="card-container">
                {header}
                {body}
                {footer}
            </div>
        </div>
    }
}

export default Card;