//import waveSvg from './wave-purple.svg';
import waveSvg from './wave.svg';

const WaveOld = () => {
    return <div className="wave">
        <img src={waveSvg} width="100%" />
    </div>
}

const Wave2 = () => <span className="wave"></span>

const Wave = () => <div className="wave">
    <svg xmlns="http://www.w3.org/2000/svg" width="1440px" height="90px" viewBox="0 0 1440 320" preserveAspectRatio='0'><path fill="#0099ff" fill-opacity="0.8" d="M0,192L48,181.3C96,171,192,149,288,133.3C384,117,480,107,576,117.3C672,128,768,160,864,192C960,224,1056,256,1152,245.3C1248,235,1344,181,1392,154.7L1440,128L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path></svg>
</div>

export default Wave2;