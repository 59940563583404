import './styles/Links.scss'
import twitter from './twitter.png';
import linkedin from './linkedin.png';
import github from './github.png';

const Links = () => {
    return <div className="links">
        <div>
            <img src={twitter} alt="twitter" />
            <a href="https://twitter.com/alichry">alichry</a>
        </div>
        <div>
            <img src={linkedin} alt="linkedin" />
            <a href="https://linkedin.com/in/alichry">alichry</a>
        </div>
        <div>
            <img src={github} alt="github" />
            <a href="https://github.com/alichry">alichry</a>
        </div>
    </div>
}

export default Links;