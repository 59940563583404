import React, { ComponentType, ReactElement } from 'react';

const findByType = (children: React.ReactNode, component: ComponentType): ComponentType | undefined => {
    
    const result: ComponentType[] = [];
    /* This is the array of result since Article can have multiple times the same sub-component */
    const typeName = [component.displayName || component.name];
    /* We can store the actual name of the component through the displayName or name property of our sub-component */
    React.Children.forEach(children, (child, i) => {
        if (!child || typeof child !== "object") {
            return;
        }
        const childType = (<ReactElement>child).type;
        if (!childType) {
            return;
        }
        // console.log("Component");
        // console.log("TypeName", typeName);
        const childTypeName = (<ComponentType>childType).displayName || (<ComponentType>childType).name;
        if (typeName.includes(childTypeName)) {
            result.push(child as ComponentType);
        }
    });
    /* Then we go through each React children, if one of matches the name of the sub-component we’re looking for we put it in the result array */
    return result[0];
};

export default findByType;