import React from 'react';
import AboutMe from './AboutMe'
import Wave from './Wave';

function App() {
  
  return <>
    <div className="content">
      <AboutMe />
    </div>
    <div className="footer">
      <Wave />
    </div>
  </>
}

export default App;
