import Card from './Card';
import Title from './Title';
import Avatar from './Avatar';
import Links from './Links';
import './styles/Me.scss';

const Me = () => {
    return <Card>
        <Card.Header>
            <Avatar />
            <Title />
        </Card.Header>
        <Card.Body>
            <p className="edu">MRes in Computer Science (UoY)</p>
            <p className="edu">BS in Computer Science (LAU)</p>

            <div className="edu-sep" />
            <p>Hello 👋 I’m Ali.</p>
            <p> I am a passionate creator and idealist. My research at the Univerity of York focuses on the security and usability of Password Managers to empower end-users with privacy- and security-enhancing tools. I enjoy automating procedures and designing systems.</p>
            <p>Let us connect! You can find me on</p>
        </Card.Body>
        <Card.Footer>
            <Links />
        </Card.Footer>
    </Card>
}

export default Me;
