import './styles/Title.scss';

const Title = () => {
    return <div className="title">
        <h4>Ali Cherry</h4>
        <h5>Software Engineer</h5>
        <h5>R.I. Security and Privacy</h5>
    </div>
}

export default Title;
