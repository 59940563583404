import './styles/CardGroup.scss';
import Me from './Me';
import Journey from './Journey';
import Scope from './Scope';

const CardGroup = () => {
    return <div className="cardgroup">
        <Me />
        <Journey />
        <Scope />
    </div>
}

export default CardGroup;