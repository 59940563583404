import Card from './Card';
import './styles/Journey.scss';
import compass from './compass.svg';

const Journey = () => {
    return <Card>
        <Card.Header>
            <Card.Header.Title text="Journey" img={compass} />
        </Card.Header>
        <Card.Body>
            <p className="bigletter">My journey has led me to adopt a rigorous approach on an abstract level.  I spent my late teenage days reflecting on the teachings of eastern philosophy. As I employed Life through an unorthodox manner, I did so as well with software engineering. </p>
            <p>At only 14 years old, I developed a service offering website with PayPal integration for a freelance client. I spent the following couple of years engineering several business management software culminating in a real-time, fault-tolerant interactive platform. It was thrilling, challenging, and unprecedented.</p>
            <p className="pb1">Realizing my capacity to soley cover unexplored terrains empowered me to become the man I am today.</p>
        </Card.Body>
    </Card>
}

export default Journey;